@import "rsuite/dist/rsuite.css";
@tailwind base;
@tailwind components;
@tailwind utilities;
 @import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&family=Pixelify+Sans:wght@400;500&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,700&family=Roboto&display=swap');
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
  font-family: 'Poppins';
  background-color:#eaeaea;
}
.left{
  position: absolute;
  left:22rem;
}
.rs-picker-input-group {
  height: 100%!important;
  width:100%!important;
  border: none!important;
}
.rs-input-group-inside{
  border: none!important;
}
.rs-btn-primary{
  background-color: #115FF5 !important;
}
.rs-input-group{
  cursor: pointer !important;
}
:root{
  --text-color-darkblue: #1D3F77;
  --font-weight-bolder:700;
  --color-dark:#163560;
  --light-blue:#115FF5;;
}
.width-80{
  width: 80%;
}
.height-100{
  height: 100vh;
}
.text-grey{
  color: #585858;
}
.otp-input{
  border: 1px solid #000;
  border-radius: 4px;
}
.width-64{
  width: 64%;
}
.w-20{
  width: 40%;
}
.font-big{
  font-size: 22px;
  font-weight: var(--font-weight-bolder);
}
.darker-blue-text{
  color: var(--text-color-darkblue);
}
.bolder-text{
  font-weight: var(--font-weight-bolder);
}
.form-title{
  font-size: 70px;
}
.width-75{
  width: 75%;
}
.form-control{
  background-color: #fff!important;
  border-color: #727272;
}
.form-control:focus{
  background-color: #fff!important;
  box-shadow: none;
}

.form-check-input {
  border-color: #000;
}
.input-group-text {
  background-color: #fff!important;
  color: black;
}
.column-1{
  background-color: #fff;
  height: 100vh;
}
.authentication-button{
  width: 100%;
  background-color: var(--light-blue);
  color: #fff;
}
button{
  font-weight: 600;
  cursor: pointer;
}
.account-creation-button{
  width: 100%;
  background-color: #fff;
  color:var(--light-blue);
  border: 2px solid var(--light-blue);
}
.header{
  position: fixed;
  top: 0;
  left: 0;
  
  height: 70px; 
  
  z-index: 1;
  background-color: #fff;
}

.box-shadow{
  box-shadow: 0 1px 4px 0px #ccc; 
}
.width-30{
  height: 60px;
}
.w-30{
  width: 30%;
}
.avatar{
  height: 36px;
}
.sidebar{
  position: fixed;
  /* top: 80px; Adjust top position to match top nav height */
  left: 0;
  width: 14%; /* Adjust width as needed */
  height:100vh; /* Adjust height as needed */
  background-color: #fff;
  /* Box shadow on all sides except top */
  /* box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1), -4px 4px 4px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1); */
  z-index: 40;}

/* Adjust content position to prevent overlap */
.content {
  
  margin-top: 80px; /* Adjust margin top to match top nav height */
  /* Adjust margin left to match side nav width */
}
.sidebar-text{
  color: var(--text-color-darkblue);
  cursor: pointer;
  
}
.icon{
  fill: #034EA2;
}
.icon.active{
  fill: #fff;
}
.sidebar-text.active{
  fill: #fff;
  background-color: 
  #034EA2;
  color: #fff;
}
.sidebar-text a{
  text-decoration: none;
  
}
.page-title{
  width: 100%;
  height: 50px;
  background-color: #fff;
  color: #034EA2;
  font-weight: 600;
}
.content-container-title{
  height: 47px;
  color: #fff;
}
/* .content-container .form-control,select {
  width: 420px;
} */
.content-container button.grey{
  background-color: #E2E2E2;
  color: #000;
}
.content-container button.blue{
  background-color: #6980E2;
  color: #fff;
}
.light-blue-text{
  color: var(--light-blue);
}
.height-70{
  height: 70%;
}
.table-caption{
  color: #545454;
  font-style: italic;
  
}
.vendor-form{
  border-color: #ccc;
  height: 42px;
}
.username-input{
  border: 1px solid #c0baba;
  height: 40px!important;
}

  .container {
    --uib-size: 40px;
    --uib-color: #034EA2;
    --uib-speed: 1.5s;
    --dot-size: calc(var(--uib-size) * 0.17);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: var(--uib-size);
    width: var(--uib-size);
    animation: smoothRotate calc(var(--uib-speed) * 1.8) linear infinite;
  
    
  }

  .dot {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    width: 100%;
    animation: rotate var(--uib-speed) ease-in-out infinite;
  }

  .dot::before {
    content: '';
    height: var(--dot-size);
    width: var(--dot-size);
    border-radius: 50%;
    background-color: var(--uib-color);
    transition: background-color 0.3s ease;
  }

  .dot:nth-child(2),
  .dot:nth-child(2)::before {
    animation-delay: calc(var(--uib-speed) * -0.835 * 0.5);
  }

  .dot:nth-child(3),
  .dot:nth-child(3)::before {
    animation-delay: calc(var(--uib-speed) * -0.668 * 0.5);
  }

  .dot:nth-child(4),
  .dot:nth-child(4)::before {
    animation-delay: calc(var(--uib-speed) * -0.501 * 0.5);
  }

  .dot:nth-child(5),
  .dot:nth-child(5)::before {
    animation-delay: calc(var(--uib-speed) * -0.334 * 0.5);
  }

  .dot:nth-child(6),
  .dot:nth-child(6)::before {
    animation-delay: calc(var(--uib-speed) * -0.167 * 0.5);
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    65%,
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes smoothRotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .swal2-modal {
    width:500px; /* Set your desired width */
    height: 280px;
  }
  .swal2-title {
    font-size: 20px; /* Set your desired font size */
    
  }
  
  /* Adjust the size of the text content */
  .swal2-content {
    font-size: 14px; /* Set your desired font size */
  }
  
  /* Adjust the size of the confirm button */
  .swal2-confirm {
    width: 64px;
  }
  .swal2-icon {
    font-size: 10px; /* Adjust the font size to change the size of the icon */
  }
  @media screen and (max-width: 768px) {
    body {
      background-color: white;
    }
  }
  @media screen and (max-width: 768px) {
    body {
      background-color: #f5f5f5;
    }
    .divider{width: 120px;}
    .sidebar{
      width: 80%;
    }
    /* .content{
      margin-top: 7rem;
    } */
  }
  .loader {
    --d:22px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    color: #1E40AF;
    
    box-shadow: 
      calc(1*var(--d))      calc(0*var(--d))     0 0,
      calc(0.707*var(--d))  calc(0.707*var(--d)) 0 1px,
      calc(0*var(--d))      calc(1*var(--d))     0 1px,
      calc(-0.707*var(--d)) calc(0.707*var(--d)) 0 2px,
      calc(-1*var(--d))     calc(0*var(--d))     0 3px,
      calc(-0.707*var(--d)) calc(-0.707*var(--d))0 4px,
      calc(0*var(--d))      calc(-1*var(--d))    0 4px;
    animation: l27 1s infinite steps(8);
  }
  @keyframes l27 {
    100% {transform: rotate(1turn)}
  }